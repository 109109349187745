import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Helmet } from 'react-helmet';
import DiscoBallImage from './assets/disco-ball.png';
import PlinkyAppIcon from './assets/app-icon-plinky.png';
import ShortCircuitAppIcon from './assets/app-icon-short-circuit.png';

const gradientAnimation = keyframes`
  0% {
	background-position: 0% 50%;
  }
  50% {
	background-position: 100% 50%;
  }
  100% {
	background-position: 0% 50%;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(-45deg, #F3825B, #E3724B, #C3522B, #A3320B);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 8s ease infinite;
`;

const Title = styled.div`
  font-family: 'Courier New', Courier, monospace;
  font-size: xx-large;
  font-weight: bold;
  text-align: center;
  margin: 20px 20px;
  color: #F0F0F0;
  &:hover {
	  color: #eee;
  }
`;

const LinksWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: xx-large;
`;

const Link = styled.a`
  display: block;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: xx-large;
  text-align: center;
  margin: 4px;
  color: #fff;
  &:hover {
	color: #eee;
  }
`;

const Footer = styled.div`
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: large;
  text-align: center;
  margin: 30px 30px;
  color: #F0F0F0;
  &:hover {
	  color: #eee;
  }
`

const Subtitle = styled.div`
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: medium;
	text-align: center;
  color: #FFF;
  a {
	  font-size: medium;
`;

const FooterLink = styled(Link)`
  display: inline;
  font-size: large;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
`;

const AppsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  & > * {
	flex-basis: calc(25% - 40px);
	margin: 20px;
  }
  @media screen and (max-width: 1024px) {
	& > * {
	  flex-basis: calc(33.33% - 40px);
	}
  }
  @media screen and (max-width: 768px) {
	& > * {
	  flex-basis: calc(50% - 40px);
	}
  }
  @media screen and (max-width: 480px) {
	& > * {
	  flex-basis: calc(100% - 40px);
	}
  }
`;

const AppLink = styled(Link)`
	display: flex; 
	align-items: center;
	text-decoration: none;
	flex-direction: column;
`;

const AppIcon = styled.img`
  width: 150px;
  height: 150px;
  margin: 20px;
  border-radius: 32px;
  transition: transform 0.25s;
  &:hover {
	transform: translateY(-5px);
	transform: scale(1.08);
  }
`;

const Hanger = styled.div`
  background: #fffa;
  width: 1px;
  border-left: 2px solid #fffa;
  border-right: 2px solid #fffa;
  height: 60px;
  position: absolute;
  top: 0;
  left: 50%;
`

const DiscoBallWrapper = styled.div`
  width: 150px;
  height: 150px;
  margin-top: 50px;
  background-image: url(${DiscoBallImage});
  background-size: contain;
  animation: rotate 1s linear infinite;
  @keyframes rotate {
	from {
	  filter: hue-rotate(0deg);
	}
	to {
	  filter: hue-rotate(360deg);
	}
  }
`;

function App() {
  return (
    <PageWrapper>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Red Panda Club" />
        <meta property="og:description" content="A club for red pandas and software developers" />
        <meta property="og:image" content="./assets/red-panda.jpg" />
      </Helmet>

      <Hanger />
      <DiscoBallWrapper />
      <Title>
        Welcome to the Red Panda Club
      </Title>
      <Subtitle>
        3% of all profits are donated to a cause near and dear to my heart, <Link href="https://redpandanetwork.org/">preserving the endangered red panda.</Link><br />
        That's the Red Panda Promise
      </Subtitle>

      <LinksWrapper>
        <Link href="https://redpandanetwork.org/Adopt/">💰 Adopt</Link>
        <Link href="mailto:hello@redpanda.club">💌 Say Hello</Link>
        <Link href="https://indieapps.space/@redpandaclub">🐘 Follow Along</Link>
        <Link href="./assets/red-panda.jpg">🦊 See A Red Panda</Link>
      </LinksWrapper>
      <AppsWrapper>

        <AppLink href="https://shortcircuit.chat/">
          <AppIcon src={ShortCircuitAppIcon} alt="Short Circuit App Icon" />
          Short<br />
          Circuit
        </AppLink>

        <AppLink href="https://plinky.app/">
          <AppIcon src={PlinkyAppIcon} alt="Plinky App Icon" />
          Plinky<br />
          (Beta)
        </AppLink>

      </AppsWrapper>

      <Footer>
        Indie apps made by <FooterLink href="https://macaw.social/@mergesort">Joe</FooterLink>, building personal, playful, productivity software<br />
      </Footer>
    </PageWrapper>
  );
}

export default App;